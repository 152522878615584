@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.timeline-item {
    opacity: 0;
    transition: opacity 0.8s ease;
}

.timeline-item.animate {
    animation: fadeInUp 0.8s ease forwards;
}